import { useState, useEffect, useContext, useMemo } from "react";
import {ProjectModal}  from "./ProjectModal";
import { addProject } from "./ProjectHelpers";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Button from "@mui/material/Button";
import { AuthContext } from "../../context/auth-context";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import  Typography  from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';


export const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editProject, setEditProject]= useState(false);
  const [currentProject, setCurrentProject] = useState(
    {
      id: "",
      name: "",
      number: "",
      active: true,
      stratus: false,
      timecards: true
    }
  )

  
  const auth = useContext(AuthContext);

  const requestConfig = useMemo(
    () => ({
      headers: {
        Authorization: "Bearer " + auth.token,
      },
    }),
    [auth.token]
  );

  // const requestConfig = {
  //   headers: {
  //     Authorization: "Bearer " + auth.token,
  //   },
  // };

  useEffect(() => {
    
    if(!isLoading){
      axios.get("/api/projects", requestConfig).then((res) => {
        if (res.data) {
          const data = res.data;
          console.log("The projects received are: ", data);
          setProjects(data);
        } else {
          console.log(res);
        }
      });
    }
    
  }, [isLoading, requestConfig]);

  const updateProjects = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        "/api/projects/updateprojects",
        requestConfig
      );
      const data = response.data;
      console.log(data);
      alert(data.messageResponse);
      setIsLoading(false);
    } catch (err) {
      console.log("Error Updating Projects: ", err);
      if (err.response) {
        console.log(err.response.data);
        alert(err.response.data);
      } else {
        alert("An error occured while updating the projects");
      }
      setIsLoading(false);
    }
  };

  const handleOpen=()=>{
    setEditProject(false)
    setOpen(true)
  };

  const handleCancel=()=>{
    setOpen(false);
    setCurrentProject({
      id: "",
      name: "",
      number: "",
      active: true,
      stratus: false,
      timecards: true
    })
  };

  const handleAddProject= async (project)=>{
    
    const id = uuidv4();
    const newProject = { ...project, id: id };
    console.log("Here is the project to be added: ", newProject);

    const newProjectList = [...projects, newProject];
    const sortedProjectList = newProjectList.sort((a, b) =>
      a.name.toLowerCase() >
      b.name.toLowerCase()
        ? 1
        : a.name.toLowerCase() <
          b.name.toLowerCase()
        ? -1
        : 0
    );
    setProjects(sortedProjectList);

    setCurrentProject(
      {
        id: "",
        name: "",
        number: "",
        active: true,
        stratus: false,
        timecards: true
      }
    )
    
    setOpen(false);

    try{
      const addedProject = await addProject(newProject,requestConfig);
      console.log("Here is the project that was added: ", addedProject);
     } catch(error){
       console.log("There was an error adding the project: ", error);
       setProjects(projects.filter((project) => project.id !== newProject.id));
     }
  };

  const handleEditProject=(rowSelected)=>{
    const project= rowSelected.row;
    setCurrentProject({
      id:project.id,
      name: project.name,
      number: project.number ? project.number : '',
      active: project.active,
      stratus: project.stratus,
      timecards: project.timecards
    });
    console.log("Here is the Project being edited: ", project);
    setEditProject(true);
    setOpen(true);
  }

  const handleUpdateProject= async (updatedProject)=>{
    
    console.log("Here is the updated project info: ", updatedProject);

    setOpen(false);

    const previousProjects= projects;

    const updatedProjects= projects.map((project)=>{
      if(project.id===updatedProject.id){
        return({
          ...updatedProject
        })
      } else{
        return project;
      }
    });

    setProjects(updatedProjects)

    setCurrentProject(
      {
        id: "",
        name: "",
        number: "",
        active: true,
        stratus: false,
        timecards: true
      }
    )

    const data ={ updatedProject: updatedProject }

    
    try{
      const modifiedProject = await axios.put(
        "/api/projects/modifyproject",
        data,
        requestConfig
      );
      console.log("Modified the following project in the database: ", modifiedProject.data.modifiedProject);
    } catch (err){
      console.log("there was an error updating the project: ", err);
      alert(err.response.data.message);
      setProjects(previousProjects);
    }


   
  }

  const columns = [
    {
      field: "number",
      headerName: "Project Number",
      width: 200,
      editable: false,
    },
    { field: "name", headerName: "Project Name", width: 500, editable: false },
    {
      field:"active",
      headerName:"Active",
      width:130,
      type:"boolean"
    },
    {
      field:"timecards",
      headerName:"Time Charged",
      width:130,
      type:"boolean"
    },
    {
      field:"stratus",
      headerName:"Stratus",
      width:130,
      type:"boolean"
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      headerName: "",
      cellClassName: "actions",
      getActions: (id) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          name="edit"
          label="Edit"
          onClick={() => {
            handleEditProject(id);
          }}
        />
      ],
    },
  ];

  return (
    <div>
      <Box
        sx={{
          width: "75%",
          padding: 2,
          display:"flex",
          flexDirection:"row",
          justifyContent:"space-between"
        }}
      >
        <Button variant="contained" onClick={() => handleOpen()}>
        Add Project
      </Button>
          <Button variant="contained" onClick={() => updateProjects()}>
           Sync Stratus Information
          </Button>
      </Box>

      <Box
        sx={{
          width: "98%",
          height: 771,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <ProjectModal
        project={currentProject}
        editProject={editProject}
        open={open}
        handleCancel={handleCancel}
        handleAddProject={handleAddProject}
        handleUpdateProject={handleUpdateProject}
      />
        {isLoading ? (
          <Box>
            <CircularProgress size={100} thickness={4} />
            <Typography variant="subtitle1" >Syncing Stratus Information</Typography>
          </Box>
        ) : (
          <DataGrid
            getRowId={(row) => row.id}
            columns={columns}
            rows={projects}
            components={{ Toolbar: GridToolbar }}
            autoPageSize
            componentsProps={{
              toolbar:{
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
              }
            }}
            disableDensitySelector={true}
            disableColumnSelector={true}
            initialState={{
              filter:{
                filterModel:{
                  items:[{columnField :'active', operatorValue:'is', value:'true'}]
                }
              }
            }}
          />
        )}
      </Box>
    </div>
  );
};
