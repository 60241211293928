import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../store/alert";
import AlertDialog from "../utilities/AlertDialog";
import { AuthContext } from "../context/auth-context";
import { combineParts } from "../../Helpers/parts";
import { OrderModal } from "./OrderModal";
import { OrderScanModal } from "./OrderScanModal";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IosShareIcon from "@mui/icons-material/IosShare";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { red } from "@mui/material/colors";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: "center",
  paddingLeft: 60,
  height: 18,
  color: theme.palette.text.primary,
}));

const OrderHeader = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center",
  paddingLeft: 60,
  color: theme.palette.text.primary,
}));

export const Order = () => {
  let params = useParams();
  let location = useLocation();
  let type = location.pathname.split("/")[3];

  console.log("Here is the order no:", params.orderno);
  console.log("Here is the orderType: ", type);

  const [docType] = useState(type);
  const [orderNo] = useState(params.orderno);
  const [order, setOrder] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const [orderItems, setOrderItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);
  const [modalType, setModalType] = useState("edit");
  const [diakinOrder, setDiakinOrder] = useState(false);

  const auth = useContext(AuthContext);

  const dispatch = useDispatch();

  const requestConfig = {
    headers: {
      Authorization: "Bearer " + auth.token,
    },
  };

  const navigate = useNavigate();

  const columns = [
    { field: "qty", headerName: "Qty", flex: 0.5 },
    {
      field: "length",
      headerName: "Length",
      type: "number",
      flex: 1,
      valueGetter: ({ value }) => Math.round(value * 100) / 100,
    },
    {
      field: order?.vendor_id === "ferg" ? "ferg_pn" : "mks_pn",
      headerName: diakinOrder ? "Diakin Part No." : "SKU",
      flex: 1,
      editable: canEdit,
    },
    {
      field: "p1_consignment_desc",
      headerName: "Diakin Part Desc",
      flex: 4,
    },
    {
      field: "p1_consignment_cost",
      headerName: "Diakin Part Cost",
      flex: 1,
    },
    {
      field: "size",
      flex: 2,
      headerName: diakinOrder ? "Modeled Size" : "Size",
    },
    {
      field: "family",
      headerName: diakinOrder ? "Modeled Description" : "Description",
      flex: 4,
    },
    {
      field: "type",
      type: "boolean",
      valueGetter: ({ value }) => (value === "scanned" ? true : false),
      headerName: "Scan",
      flex: 1,
      renderCell: (params) => {
        return params.value ? (
          <CheckIcon
            style={{
              color: red,
            }}
          />
        ) : (
          ""
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      headerName: "",
      cellClassName: "actions",

      getActions: (item) => [
        <GridActionsCellItem
          icon={<DeleteIcon color="action" />}
          name="delete"
          label="Delete"
          onClick={() => {
            handleDeleteItem(item);
          }}
          color="inherit"
        />,
      ],
    },
  ];

  // This function combines the parts received from the backend and then sets the order if it is a diakin order or not
  const processParts = (partOrderInfo, vendor) => {
    
        const combinedParts = combineParts(partOrderInfo, vendor);
    console.log("The combined parts are: ", combinedParts);

    const diakinTotalCost = combinedParts.reduce(
      (sum, a) => sum + a.p1_consignment_cost * a.qty,
      0
    );

    console.log("The Total Diakin Cost is: ", diakinTotalCost);
    if (diakinTotalCost > 1) {
      setDiakinOrder(true);
    }

    setOrderItems(combinedParts);
  };

  // this gets the order items when the order is selected.
  useEffect(() => {
    const getOrderItems = async () => {
      try {
        const orderConfig = {
          headers: {
            Authorization: "Bearer " + auth.token,
          },
          params: { orderno: orderNo, docType: docType },
        };

        const orderItemData = await axios.get(
          "/api/purchasing/order",
          orderConfig
        );

        // this gets the order info and sets the current info
        const orderInfo = orderItemData.data.orderInfo;
        console.log("Here is the order info returned from server: ", orderInfo);
        setOrder(orderInfo);

        // this takes the parts for the current order and then sets the parts in the component with them
        const orderParts = orderItemData.data.partOrderInfo;
        console.log(
          "Here are the parts returned from the server: ",
          orderParts
        );
        processParts(orderParts,orderInfo.vendor_id);

        // this sets the status
        const status =
          docType === "orders" ? orderInfo.po_status : orderInfo.ret_status;
        setOrderStatus(status);
        status === "Created" ? setCanEdit(true) : setCanEdit(false);
        setIsLoading(false);
      } catch (err) {
        // if there is an error getting the order it will alert the user and navigate back to the order list.
        console.log("There was an error getting the order: ", err);
        dispatch(
          alertActions.showAlert({
            title: "Error Retrieving Order",
            message:
              "There was an error retrieving this order.  Error:  " +
              err.message,
          })
        );
        setIsLoading(false);
        navigate("/inventory");
      }
    };

    if (auth.token) {
      getOrderItems();
    }
  }, [auth, orderNo, docType, navigate]);

  // This function sends the order to purchasing if the user selects the button
  const submitOrder = async () => {
    try {
      // this list all the skus and makes sure none are missing before sending it to purchasing {order.vendor_id==='ferg'?'Ferguson':'MKS'}
      // const orderSkus = orderItems.map((item) => item.ferg_imp_sku);
      const orderSkus = orderItems.map((item) =>
        order.vendor_id === "ferg" ? item.ferg_pn.trim() : item.mks_pn.trim()
      );
      if (orderSkus.includes("")) {
        dispatch(
          alertActions.showAlert({
            title: "Missing SKU Numbers",
            message:
              "There are missing sku numbers in this order.  Please fill in any missing sku numbers before forwarding to purchasing",
          })
        );
      } else if (!order.project_num) {
        // this checks to make sure a project number has been assigned
        dispatch(
          alertActions.showAlert({
            title: "Assign Project Number",
            message:
              "A project number has not been assigned.  Please assign a project number before forwarding to purchasing",
          })
        );
      } else {
        const data = {
          orderno: orderNo,
          orderItems: orderItems,
          doctype: docType,
        };

        setOrderStatus("Processing");
        setOrder({
          ...order,
          po_status: "Submitted",
          ret_status: "Submitted",
        });
        setCanEdit(false);
        const orderResponse = await axios.post(
          "/api/purchasing/submitOrder",
          data,
          requestConfig
        );
        console.log("orderResponse: ", orderResponse);
        setOrderStatus("Success");
      }
    } catch (err) {
      console.log("There was an error submitting this order: ", err);
      dispatch(
        alertActions.showAlert({
          title: "Error Submitting Order",
          message:
            "There was an error submitting this order: " + err.response.data,
        })
      );
      setOrder({ ...order, po_status: "Created", ret_status: "Created" });
      setCanEdit(true);
      setOrderStatus("Created");
    }
  };

  // This handles the opening of both the scanning modal and the edit PO modal and the add container modal
  const handleOpen = async (type) => {
    console.log(type);
    // The first part handles the scanning modal
    if (type === "scanItems") {
      console.log("Setting open scanner to true");
      setOpenScanner(true);
    } else {
      // the second part handles the edit PO modal and add container modal
      setModalType(type);
      setOpenEdit(true);
    }
  };

  // this closes the modals without adding anything
  const handleCancel = (modal) => {
    if (modal === "scanner") {
      setOpenScanner(false);
    } else {
      setOpenEdit(false);
    }
  };

  // this handles the editing of the PO information
  const handleEdit = async (orderChange) => {
    console.log("Here is the updated order infomation: ", orderChange);
    setOpenEdit(false);

    const orderBeforeUpdate = order;

    setOrder({
      ...order,
      cost_code: orderChange.cost_code,
      project_id: orderChange.project.id,
      project_name: orderChange.project.name,
      project_num: orderChange.project.number,
      vendor_id: orderChange.vendor_id,
    });

    try {
      const data = {
        orderInfo: {
          orderNo: orderNo,
          project_id: orderChange.project.id,
          cost_code: orderChange.cost_code,
          vendor_id: orderChange.vendor_id,
          docType: docType,
        },
      };

      const response = await axios.patch(
        "/api/purchasing/updateorderinfo",
        data,
        requestConfig
      );

      console.log(
        "Here is the response from the server for the update of the PO information: ",
        response.data.response
      );
    } catch (err) {
      console.log(
        "There was an error updating the server with the new order information",
        err
      );
      dispatch(
        alertActions.showAlert({
          title: "Error Updating Order Information",
          message:
            "There was an error updating the order information: " + err.message,
        })
      );
      setOrder(orderBeforeUpdate);
    }
  };

  // this will go to stratus and add another container to the current order
  const handleAddContainer = async (container) => {
    setOpenEdit(false);
    console.log("Here is the container that was submitted: ", container);

    try {
      const data = { orderNo: orderNo, containerId: container };
      const response = await axios.patch(
        "/api/purchasing/addcontainercontents",
        data,
        requestConfig
      );

      console.log(
        "Here is the response from the server to add the container contents: ",
        response
      );

      const { partOrderInfo, container_id, container_name } = response.data;

      // this gets the current parts with the added container and then processes them to combine them
      processParts(partOrderInfo, order.vendor_id);

      setOrder({
        ...order,
        container_id: [order.container_id, container_id],
        container_name: order.container_name + ", " + container_name,
      });
      dispatch(
        alertActions.showAlert({
          title: "Container Added",
          message: `${container_name} has been added to this order`,
        })
      );
    } catch (err) {
      console.log("There was an error adding the container: ", err);
      dispatch(
        alertActions.showAlert({
          title: "Error Adding Container",
          message:
            "There was an error adding the container:  " +
            err.message +
            err.response.data,
        })
      );
    }
  };

  // This function adds scanned items to the order.  It takes items from the ScanModal and adds them to the current list then sends them
  //to the back end to be added.
  const handleAddItems = async (items) => {
    try {
      // this adds the items to the current list of parts combines them and then sets the current order parts to the combined parts
      console.log("Here are the items to add to the order: ", items);
      const allParts = orderItems.concat(items);
      const combinedParts = combineParts(allParts);
      setOrderItems(combinedParts);

      const data = {
        orderno: orderNo,
        addedItems: items,
        docType: docType,
      };

      const response = await axios.post(
        "/api/purchasing/postscanneditems",
        data,
        requestConfig
      );

      console.log(
        "Here is the response for adding the items to the server: ",
        response
      );

      const numItemsAdded = response.data.numItemsAdded;
      if (numItemsAdded !== items.length) {
        dispatch(
          alertActions.showAlert({
            title: "Not All Items Added",
            message: `Not all the items were added to this order.  Only ${numItemsAdded} were added out of ${items.length}.`,
          })
        );
        window.location.reload();
      } else {
        console.log(
          "Success adding items to the database. The number of parts matched. The total number of items added were: ",
          numItemsAdded
        );
      }
    } catch (err) {
      console.log("Problem adding items to the order: ", err);
      dispatch(
        alertActions.showAlert({
          title: "Problem Adding Items to Order",
          message:
            "There was a problem adding these items to the order.  They have not been added. Error Message: " +
            err.message,
        })
      );
      window.location.reload();
    }
  };

  // this deletes the current order. Note it is only a soft delete and just marks the order as deleted. The order information is retained
  const deleteOrder = async () => {
    if (window.confirm("Are you sure you would like to delete this order?")) {
      setOrderStatus("Deleted");
      setCanEdit(false);

      const statusName = docType === "orders" ? "po_status" : "ret_status";
      setOrder({ ...order, [statusName]: "Deleted" });

      const requestData = {
        headers: {
          Authorization: "Bearer " + auth.token,
        },
        params: { orderId: orderNo, docType: docType },
      };

      try {
        const response = await axios.delete(
          "/api/purchasing/deleteorder",
          requestData
        );
        console.log("Response deleting order: ", response);
        dispatch(
          alertActions.showAlert({
            title: "Order Deleted",
            message: "The Order Has Been Deleted",
          })
        );
      } catch (error) {
        console.log("There was an error deleting the order: ", error);
        dispatch(
          alertActions.showAlert({
            title: "Error Deleting Order",
            message: "There was an error deleting the order: " + error.message,
          })
        );
        setOrderStatus("Created");
        setOrder({ ...order, po_status: "Created", ret_status: "Created" });
      }
    }
  };

  // This handles when a user changes the sku for a part in the order
  const handleSkuChange = async (e) => {
    const resetItems = () => {
      const sameItems = orderItems.map((item) => {
        return { ...item };
      });
      setOrderItems(sameItems);
    };
    console.log("Changing the Sku for: ", e);

    const rowId = e.id;

    const rowInfo = orderItems.find(row=>row.id === rowId);

    console.log("Here is the rowInfo for this row: ", rowInfo);

    const type = rowInfo.type;
    if (type !== "scanned") {
      const changeId = rowId;
      const itemsChanged = rowInfo.ids;
      const changeName = rowInfo.size + " " + rowInfo.family;
      const fieldChanged = e.field;
      const newValue = e.value;
      const changeItem = window.confirm(
        `Do you want to change the sku number on ${changeName} to ${newValue} `
      );

      if (changeItem) {

        const orderItemsBefore = orderItems;

        try{
          const updatedOrderItems = orderItems.map((item) => {
            if (item.id === changeId) {
              return { ...item, [fieldChanged]: newValue };
            }
            return item;
          });
  
          setOrderItems(updatedOrderItems);
          
          console.log("The ids of the changed items are", itemsChanged);
          
          const data = {
            ids: itemsChanged,
            [fieldChanged]: newValue,
          };
          
          const response = await axios.patch("/api/purchasing/updatesku", data, requestConfig);
          
          console.log("Here is the response on updating the sku's:", response);

          

        } catch(err){
          console.log("There was an error updating the sku's on the server: ", err);
          window.alert("There was an error updating the SKU on the server.  Please contact Jeff Peterson: " + err.response.data)
          setOrderItems(orderItemsBefore);
        }

      } else {
        resetItems();
      }
    } else {
      dispatch(
        alertActions.showAlert({
          title: "Cannot Change",
          message: "You can not change the sku of a scanned item.",
        })
      );
      resetItems();
    }
  };

  const handleDeleteItem = async (item) => {
    console.log("Here is the item being deleted: ", item);
    const rowInfo = item.row;
    const numDeleted = rowInfo.qty;
    const nameDeleted = rowInfo.family;
    const sizeDeleted = rowInfo.size;
    const type = rowInfo.type;

    console.log(type);

    let message;

    const stratusMessage =
      type === "scanned"
        ? ""
        : "  Please note this will not update the containers contents in Stratus.";

    if (type === "scanned" && numDeleted > 1) {
      message = `Since these are scanned items all ${numDeleted} items will be deleted.  If you would like a different qty then reenter the items with the correct quantity`;
    } else {
      if (numDeleted > 1) {
        message = `Since these are items from Stratus this will delete the first item of ${sizeDeleted} ${nameDeleted} from ${orderNo} out of ${numDeleted}.${stratusMessage}   Please confirm you would like to delete one of these items.`;
      } else {
        message = `This will delete ${sizeDeleted} ${nameDeleted} from ${orderNo}.${stratusMessage}  Please confirm you would like to delete this item.`;
      }
    }

    if (window.confirm(message)) {
      const prevOrderItems = orderItems;

      try {
        const idDeleted = rowInfo.ids[0];
        const rowRemoved = rowInfo.identifier;
        const itemType = rowInfo.type;

        // this first deletes one from the quantity and takes out the first id

        let newOrderItems;

        if (itemType === "scanned") {
          newOrderItems = orderItems.filter((item) => item.id !== idDeleted);
        } else {
          newOrderItems = orderItems.map((item) => {
            if (item.identifier === rowRemoved) {
              return {
                ...item,
                qty: item.qty - 1,
                ids: item.ids.filter((id) => id !== idDeleted),
              };
            } else {
              return item;
            }
          });
        }

        // if the quantity is zero then it removes that item from the list.
        newOrderItems = newOrderItems.filter((item) => item.qty !== 0);

        console.log(
          "Here are the new order items after deletion: ",
          newOrderItems
        );
        setOrderItems(newOrderItems);

        const requestData = {
          headers: {
            Authorization: "Bearer " + auth.token,
          },
          params: {
            orderId: orderNo,
            deletedItem: idDeleted,
            itemType: itemType,
            docType: docType,
          },
        };

        const response = await axios.delete(
          "/api/purchasing/deleteorderitem",
          requestData
        );
        const itemDeleted = response.data.deletedItem;
        console.log("Deleted the following item: ", itemDeleted);
      } catch (err) {
        console.log("Error deleting the items in the backend: ", err);
        dispatch(
          alertActions.showAlert({
            title: "Error Deleting Items",
            message:
              "There was an error deleting these items from the order: " +
              err.message,
          })
        );
        setOrderItems(prevOrderItems);
      }
    }
  };

  // this checks if the order is loading and if so shows a loading dialogue.  Then it checks if it is processing an order and shows a processing dialogue
  if (isLoading) {
    return <div>Loading Items...</div>;
  } else if (orderStatus === "Processing") {
    return (
      <div>
        <Stack pt={10} sx={{ color: "grey.500" }} spacing={2} direction="row">
          <CircularProgress size={100} thickness={4} />
        </Stack>
        <h1>Processing order for {orderNo}...</h1>
      </div>
    );
  } else if (orderStatus === "Success") {
    return (
      <Stack pt={10} sx={{ width: "75%" }} alignItems="center" spacing={2}>
        <Alert
          onClose={() => {
            setOrderStatus("Submitted");
          }}
        >
          Your Purchase Order Was Sent
        </Alert>
      </Stack>
    );
  } else {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <AlertDialog />
        <Box sx={{ width: diakinOrder ? "100%" : "75%", paddingTop: 2 }}>
          <Grid container justifyContent="flex-end">
            {orderStatus === "Created" && (
              <Grid>
                {!diakinOrder && (
                  <Tooltip title="Submit" placement="bottom-start">
                    <IconButton disabled={diakinOrder} onClick={submitOrder}>
                      <IosShareIcon
                        color="action"
                        fontSize="large"
                        sx={{ mr: 2 }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Edit Order" placement="bottom-start">
                  <IconButton id="edit" onClick={() => handleOpen("edit")}>
                    <EditIcon id="edit" color="action" fontSize="large" />
                  </IconButton>
                </Tooltip>
                {docType === "orders" && (
                  <Tooltip title="Add Container" placement="bottom-start">
                    <IconButton
                      id="addContainer"
                      onClick={() => handleOpen("addContainer")}
                    >
                      <AddShoppingCartIcon
                        id="addContainer"
                        color="action"
                        fontSize="large"
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Add Items to Order" placement="bottom-start">
                  <IconButton
                    id="scanItems"
                    onClick={() => handleOpen("scanItems")}
                  >
                    <QrCode2Icon
                      id="scanItems"
                      color="action"
                      fontSize="large"
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Order" placement="bottom-start">
                  <IconButton onClick={deleteOrder}>
                    <DeleteIcon color="action" fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}

            <Tooltip title="Return to Order List" placement="bottom-start">
              <IconButton
                onClick={() => {
                  const url = `/inventory/vmi/${
                    docType === "orders" ? "orders" : "returns"
                  }`;
                  navigate(url);
                }}
              >
                <ArrowBackIcon color="action" fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
          <OrderModal
            open={openEdit}
            order={{
              cost_code: order.cost_code,
              project: {
                id: order.project_id,
                number: order.project_num,
                name: order.project_name,
              },
              vendor_id: order.vendor_id,
            }}
            handleCancel={handleCancel}
            handleEditCommit={handleEdit}
            handleAddContainer={handleAddContainer}
            modalType={modalType}
            modifyVendor={orderItems.length === 0 ? true : false}
          />
          <OrderScanModal
            scannerOpen={openScanner}
            scannerCancel={handleCancel}
            handleAddItems={handleAddItems}
            vendor_id={order.vendor_id}
          />
          <Stack spacing={2}>
            <OrderHeader>
              {docType === "orders" ? order.vmi_order_no : order.vmi_return_no}
            </OrderHeader>
            <Item>Project: {order.project_name}</Item>
            <Item>Project Number: {order.project_num}</Item>
            <Item>CC: {order.cost_code}</Item>
            <Item>
              Vendor: {order.vendor_id === "ferg" ? "Ferguson" : "MKS"}
            </Item>
            <Item>
              Status:{" "}
              {docType === "orders" ? order.po_status : order.ret_status}
            </Item>
            {docType === "orders" && (
              <Item>Container: {order.container_name}</Item>
            )}
          </Stack>
        </Box>

        <div
          style={{
            height: 400,
            width: diakinOrder ? "100%" : "75%",
            paddingTop: 20,
          }}
        >
          <DataGrid
            rows={orderItems}
            columns={columns}
            onCellEditCommit={handleSkuChange}
            autoPageSize
            components={{ Toolbar: GridToolbar }}
            disableDensitySelector={true}
            disableColumnSelector={true}
            columnVisibilityModel={{
              length: diakinOrder,
              p1_consignment_desc: diakinOrder,
              p1_consignment_cost: diakinOrder,
              actions: canEdit,
            }}
          />
        </div>
      </Box>
    );
  }
};
