import { useState, useContext } from "react";
import "./PowerBILink.css";
import Button from "@mui/material/Button";
import axios from "axios";
import { AuthContext } from "../context/auth-context";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import  Typography  from "@mui/material/Typography";


export const PowerBILink = () => {
  const [isLoading, setIsLoading] = useState(false);

  const auth = useContext(AuthContext);

  const requestConfig = {
    headers: {
      Authorization: "Bearer " + auth.token,
    },
  };

const refreshData= async ()=>{
  setIsLoading(true);
 console.log("Refresh Selected");
 try{
  const response = await axios.get("/api/production/refreshdata",requestConfig);
  console.log("Here is the response from the server after refreshing the reports: ", response);
  alert("The data is being updated.  Please refresh your screen in a couple of minutes and verify the refresh date below: " + response.data.response + ".  Please note that we are limited to 6 refreshes per day");
  setIsLoading(false);

 }catch(err){
  console.log("There was an error refreshing the production reports: ", err);
  alert("there was an error refreshing the data: " + err.response.data.message);
  setIsLoading(false);
 }
}

  return (
    <Box
    sx={{
    display: "flex",
    flexDirection: "row",
    paddingTop:1
    }}
    >
      <div className="container">
        <iframe
          title="P1 Pipe Shop Stratus v10"
          className="responsive-iframe"
          // style={{width:'100%', height:'100%'}}
          // width="1732.8"
          // height="822.7"
          // width="100%"
          // height='100%'
          // this is the old source
          // src="https://app.powerbi.com/reportEmbed?reportId=4cce9470-2fc1-4107-9cf9-b37799532cc5&autoAuth=true&pageName=ReportSectione573deb0634b8de60fcf"
          src="https://app.powerbi.com/reportEmbed?reportId=87600f64-7c61-426a-a6f8-4f17324bf8fb&autoAuth=true&ctid=332759ea-2a45-492d-a599-06d7c83259ad"
          frameborder="0"
          allowFullScreen="true"
        />
      </div>
      <Box
      sx={{
        padding:5,
      }}
      >
                {isLoading ? (
          <Box>
            <CircularProgress size={100} thickness={4} />
            <Typography variant="subtitle1" >Refreshing Data</Typography>
          </Box>
        ) : (
      <Button variant="contained" onClick={() => refreshData()}>
        Refresh
      </Button>   
        )}

      </Box>
    </Box>
  );
};
