import React from "react";
import { AuthContext } from "./Components/context/auth-context";
import { Projects } from "./Components/admin/projects/Projects";
import {InventoryHeader} from "./Components/inventory/InvHeader";
import {Order} from "./Components/inventory/Order";
import {OrderList} from "./Components/inventory/OrderList";
import SignIn from "./Components/Login";
import { Nav } from "./Components/Nav";
import { SideNav } from "./Components/SideNav";
import { useAuth } from "./Hooks/auth-hook";
import { Timecard } from "./Components/timecards/Timecard";
import { TimecardDownload } from "./Components/timecards/TimecardDownload";
import { Users } from "./Components/admin/users/users";
import { Packages } from "./Components/admin/packages/Packages";
import { ChangePassword } from "./Components/admin/users/ChangePassword";
import { PowerBILink } from "./Components/productionReports/PowerBILink";
import { BrowserRouter, Routes, Route, Navigate, Outlet, useParams } from "react-router-dom";
import { OrderRedirect } from "./Components/inventory/OrderRedirect";



const App = () => {
  const {
    token,
    login,
    logout,
    userId,
    userEmail,
    userFirstName,
    userLastName,
    isAdmin,
    resetPass,
    setResetPass,
  } = useAuth();

  if (token) {
    console.log("Received the token inside of app");
    console.log("The reset password value is: ", resetPass);
  } else {
    console.log("No token inside of App");
  }

  if (!isAdmin) {
    console.log("Not admin");
    <Navigate replace to="/timecards" />;
  }

  return (
    <div className="App">
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          token: token,
          userId: userId,
          login: login,
          logout: logout,
          userEmail: userEmail,
          userFirstName: userFirstName,
          userLastName: userLastName,
          isAdmin: isAdmin,
          resetPass: resetPass,
          setResetPassword: setResetPass,
        }}
      >
        <BrowserRouter>
          {/* {token && <SideNav />} */}

          <main>
            <Routes>
              <Route
                path="/"
                element={
                  token ? (
                    resetPass ? (
                      <ChangePassword />
                    ) : isAdmin ? (
                      <div>
                        <Nav />
                      </div>
                    ) : (
                      <div>
                        <Nav />
                        <Timecard />
                      </div>
                    )
                  ) : (
                    // (!isAdmin && <Timecard/>)
                    <SignIn />
                  )
                }
              >
                <Route
                  path="projects"
                  element={isAdmin ? <Projects /> : <Navigate replace to="/" />}
                />
                <Route
                  path="packages"
                  element={isAdmin ? <Packages /> : <Navigate replace to="/" />}
                />
                <Route
                  path="users"
                  element={isAdmin ? <Users /> : <Navigate replace to="/" />}
                />
                <Route
                  path="timecards"
                  element={isAdmin ? <Timecard /> : <Navigate replace to="/" />}
                />
                <Route
                  path="reports"
                  element={
                    isAdmin ? <PowerBILink /> : <Navigate replace to="/" />
                  }
                />
                <Route
                  path="timecardsweek"
                  element={
                    isAdmin ? <TimecardDownload /> : <Navigate replace to="/" />
                  }
                />
                <Route path="changepassword" element={<ChangePassword />} />
                <Route path="vmiorder/:orderno" element={<OrderRedirect/>}/>
                <Route path="inventory" element={<InventoryHeader />}>
                  <Route path="vmi">
                    <Route
                      path="orders"
                      element={
                        isAdmin ? (
                          <main style={{ padding: "1rem" }}>
                            <OrderList type="orders" />
                          </main>
                        ) : (
                          <Navigate replace to="/timecards" />
                        )
                      }
                    />
                    <Route
                      path="orders/:orderno"
                      element={
                        isAdmin ? (
                          <Order />
                        ) : (
                          <Navigate replace to="/timecards" />
                        )
                      }
                    />
                    <Route
                      path="returns/:orderno"
                      element={
                        isAdmin ? (
                          <Order />
                        ) : (
                          <Navigate replace to="/timecards" />
                        )
                      }
                    />
                    <Route path="inventory" element={<Outlet/>}/>
                    <Route path="restocks" element={<Outlet/>}/>
                    <Route
                      path="returns"
                      element={
                        isAdmin ? (
                          <main style={{ padding: "1rem" }}>
                            <OrderList type="returns" />
                          </main>
                        ) : (
                          <Navigate replace to="/timecards" />
                        )
                      }
                    />
                  </Route>
                  <Route path="aquatherm">
                    <Route path="inventory" element={<Outlet/>}/>
                    <Route path="orders" element={<Outlet/>}/>
                    <Route path="restocks" element={<Outlet/>}/>
                    <Route path="returns" element={<Outlet/>}/>
                  </Route>
                  <Route path="p1parts">
                    <Route path="inventory" element={<Outlet/>}/>
                    <Route path="orders" element={<Outlet/>}/>
                    <Route path="restocks" element={<Outlet/>}/>
                    <Route path="returns" element={<Outlet/>}/>
                  </Route>
                </Route>
                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>There's nothing here!</p>
                    </main>
                  }
                />
              </Route>
            </Routes>
          </main>
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
};

export default App;
