import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from './store/index'

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from 'axios'

axios.defaults.baseURL = process.env.NODE_ENV ==="production" ? "" :"http://localhost:4000";
// axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
// axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(request => {
    console.log("Here is the request in the axios interceptor in index.js: ", request);
    // Edit request config
    return request;
}, error => {
    console.log("Here is the error in the request  axios interceptor in index.js: ", error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    console.log("Here is the response in the axios interceptor in index.js: ",response);
    // Edit response config
    return response;
}, error => {
    console.log("Here is the response error in the axios interceptor in index.js: ",error);
    return Promise.reject(error);
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
